<template>
  <div id="app">
    <Header />
    <transition name="fade">
      <!-- <router-view class="mx-1 mx-lg-3" /> -->
      <router-view />
    </transition>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  components: { Header },
};
</script>

<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap";

h1 {
  @extend .text-center;
}

.divider {
  @extend .border;
  @extend .border-dark;
}

/* .fade-leave-active */
.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  opacity: 1;
}

/* Spacing between cards in cards */
.card .card {
  @extend .mb-3;
}

.disable-tablist ul[role="tablist"] {
  pointer-events: none;
}

table.table-right tr :not(td:first-child) {
  text-align: right;
}

/* vue-select dropdown remove border issues */
.vs__dropdown-toggle {
  height: 100%;
  border: none !important;
}
</style>
